@import "../../assets/scss/mixin.scss";

.work-order-bottom-btn {
  background-color: rgba(230, 230, 230) !important;
  position: sticky;
  bottom: -1px;

  .simple-btn {
    min-width: auto !important;
    padding: 20px 10px;
    border-radius: 5px;
    font-size: small;
    font-weight: 500;
  }
}


.pdf-viewer {
  width: 100%;
  height: 800px;
}

.table-edit-btn {
  min-width: 20px !important;
  height: 100% !important;
}

.bg-pri {
  background-color: #092558 !important;
}

.bg-secondri {
  background-color: #2d6035 !important;
  border-color: #2d6035;
}

.workorder-import-btn {
  min-width: 30px !important;
  height: 20px !important;
}

.min-width {
  min-width: 110px !important;
}

.table-del-btn-min-w-20px {
  min-width: 20px;
  min-height: 20px;
}

.cross-btn {
  background-color: #975858 !important;
  height: 33px !important;
  width: 33px !important;
}

.select-time-report {
  --webkit-appearance: none !important;
  height: 35px !important;
  background-color: #f5f5f5 !important;
  border: 1px solid #e2e0e0 !important;
}

.work-order-wrapper {
  // height: 540px !important;
  // overflow-y: scroll;
  .nav-tabs {
    gap: 2px;
    flex-wrap: wrap;
    .nav-item {
      padding: 0;
      margin: 0;
      width: 24.78%;
      text-overflow: ellipsis;
      overflow-x: hidden;
      @include font(
        $family: (
          "Poppins",
          sans-serif,
        ),
        $size: 14px,
        $fWeight: bold
      );
      // min-width: 24%;
      // flex: 1 0 21%;
      // flex-grow: 1;
      // width: calc(100% * (1 / 4) - 10px - 1px);
      @media screen and (min-width: $breakpoint_laptop) {
        // width: 19.8%;
        .nav-link {
          padding: 15px;
        }
      }

      @media screen and (max-width: $breakpoint_mobile_landscape) {
        width: 49.7%;
        .nav-link {
          padding: 15px;
        }
      }

      @media screen and (max-width: $breakpoint_small_portrait) {
        width: 100%;
        .nav-link {
          padding: 10px;
        }
      }

      .nav-link {
        padding: 20px auto;
        white-space: nowrap !important;
      }
      

    }
  }

  .form-design {
    .form-label {
      margin-bottom: 0;
      margin-left: 8px;
    }

    .form-control,
    .form-select {
      height: 35px;
      border: 0;
      outline: none;
      padding: 0.25rem 1rem !important;
      font-size: 1rem;
      border: 1px solid #e2e0e0;
      border-radius: 0.1rem;
      background-color: rgba(230, 230, 230, 0.6);
    }

    .form-control:focus,
    .form-select:focus {
      border: 2px solid rgba(30, 85, 250, 0.47);
      background-color: #fff !important;
      outline: none;
      box-shadow: none;
    }
  }

  .tab-heading {
    background-color: rgba(230, 230, 230);
    hr {
      margin: 0 auto;
      border-top: 3px solid #000 !important;
      height: 1px !important;
      width: 50px !important;
    }
  }

  .delete-btn {
    border: none;
    height: 40px;
    display: flex;
    justify-content: center;
    background-color: #0a2558;
    margin-left: 5px;
  }

  .table-custum {
    .sc-fKVrsT {
      display: block;
    }
    .rdt_TableHead {
      height: 100px;
      .rdt_TableHeadRow {
        padding-left: 0;
        border-bottom: none !important;
        .rdt_TableCol {
          padding: 16px;
          margin: 2px 2px 2px 0;
          &:first-child,
          &:last-child {
            border-radius: 0% !important;
          }
        }
      }
    }
    .rdt_TableBody {
      .rdt_TableRow {
        border: none !important;
        background-color: #ffffff !important;
        padding-left: 0;
        &:last-child {
          border: none !important;
        }
        .rdt_TableCell {
          border: 1px solid #c5c5c5;
          padding-left: 16px;
          margin: 2px 2px 2px 0;
        }
      }
    }
  }

  .table-custum1 {
    .sc-fKVrsT {
      display: block;
    }
    .rdt_TableHead {
      .rdt_TableHeadRow {
        padding-left: 0;
        border-bottom: none !important;
        .rdt_TableCol {
          width: 40px !important;
          // max-width: 50px !important;
          padding: 16px;
          margin: 2px 2px 2px 0;
          &:first-child,
          &:last-child {
            border-radius: 0% !important;
          }
          .rdt_TableCol_Sortable {
            // width: max-content !important;
            .table-content {
              // max-width: max-content !important;
              white-space: nowrap !important;
            }
          }
        }
      }
    }
    .rdt_TableBody {
      .rdt_TableRow {
        border: 0 !important;
        background-color: #ffffff !important;
        padding-left: 0;
        &:last-child {
          border: 0 !important;
        }
        .rdt_TableCell {
          border: 1px solid #c5c5c5;
          margin: 2px 2px 2px 0;
          width: auto !important;
        }
      }
    }
  }
}

.km-field {
  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      white-space: nowrap;
      margin-bottom: 1rem;
    }
  }
}

.datetime-design .row > * {
  padding: 0 15px;
  .simple-btn {
    min-width: 100%;
    text-transform: uppercase;
  }
}

.search-and-select-input {
  .css-13cymwt-control,
  .css-t3ipsp-control {
    min-width: 200px;
    border: 1px solid #a3a3ab;
    border-radius: 5px;
    color: #07070c;
    height: 40px;
    background-color: #f1f1f1;
    font-size: 14px !important;
  }
}

.InvoicesTab {
  .list-pdf {
    img {
      width: 30px;
    }
    p {
      text-transform: capitalize;
      @include font(
        $family: (
          "Poppins",
          sans-serif,
        ),
        $size: 14px,
        $fWeight: 500
      );
    }
    button {
      width: 150px;
    }
  }
  .heading-main {
    background: #0a2558;
    color: #ffffff;
    text-transform: capitalize;
    @include font(
      $family: (
        "Poppins",
        sans-serif,
      ),
      $size: 14px,
      $fWeight: bold
    );
  }
  .heading {
    background: #eae8e8;
    border-bottom: 2px solid #c0cfbe;
    text-transform: capitalize;
    @include font(
      $family: (
        "Poppins",
        sans-serif,
      ),
      $size: 14px,
      $fWeight: bold
    );
  }
  .fa-save {
    color: rgb(8, 97, 251);
  }
  .fa-arrow-right {
    color: rgb(8, 214, 251);
  }
  .fa-arrow-down {
    color: #87d898;
  }
  .invoiceForm {
    background-color: #f1f1f1;

    .form-design {
      .form-control,
      .form-select {
        border: 2px solid #bebcbc !important;
        background-color: #ffffff !important;
        border-radius: 0.25rem;
        margin-bottom: 1px;
        i {
          margin-right: -120% !important;
        }
      }
      .form-control:focus,
      .form-select:focus {
        border: 2px solid rgba(30, 85, 250, 0.47);
        background-color: #fff !important;
        outline: none;
        box-shadow: none;
      }
    }
  }
}
.wrappFavorite{
  display: flex;
  .material-table {
    overflow: auto !important;
    // width: 80%;
    min-width: 80% !important;
    border-radius: 10px 0px 0px 0 !important;
    table {
      min-width: 100%;
      width: 1800px;
      max-width: unset;
      margin-bottom: 0 !important;
      .col-w-100px {
        min-width: 100px !important;
        width: 100px !important;
      }
      .col-w-120px {
        min-width: 120px !important;
        width: 120px !important;
        padding: 15px 0 15px 15px !important;
      }
      .col-w-150px {
        min-width: 150px !important;
        width: 150px !important;
      }
      .col-w-450px {
        min-width: 450px !important;
        width: 450px !important;
      }
      th {
        padding: 15px 15px;
      }
      td {
        padding: 5px 0 5px 15px;
        i { cursor: pointer }
        .fa-star{
          color: orange;
        }
      }

      th,
      td {
        max-width: 150px;
        text-align: left !important;
      }
      .table-del-btn-min-w-20px {
        min-width: 20px;
        min-height: 20px;
      }
    }
  }
  .favorite{
    width: 20%;
    min-width: 20%;
    background-color: whitesmoke;
    border-radius: 0px 10px 0px 0px;
    p{
      background: #cecece;
      color: #0a2558;
      font-weight: 400;
      padding: 15px;
      line-height: 22px;
      text-align: center;
      border-radius: 0px 10px 0px 0px;
      margin: 0;
    }
    .list{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 62px);
      margin: 10px 0;
  
      ul{
        margin-bottom: 0;
        li{
          list-style: none;
          margin-bottom: 15px;
          &:last-child{
            margin-bottom: 0;
          }
          .form-check-input {
            width: 15px !important;
            height: 15px;
            background-color: none;
            border: 1px solid #0a2558;
            background-image: var(--bs-form-check-bg-image) !important;
            margin-right: 10px;
            &:checked {
              background-color: #0a2558;
            }
            &:focus {
              outline: none;
              box-shadow: none;
            }
        }
        }
      }
      button{
        width: 100%;
        padding: 10px;
        background-color: #0a2558;
        color: white;
        border: none;
        &:hover{
          background-color: #0a2558;
        }
      
      }
    }
  }
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: black;
  font-size: 14px;
  
}
.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}
.create-btn {
  border: 3px solid lightgray;
  padding: 10px;
  font-weight: 500;
  position: relative;
  width: 195px;
  text-align: center;
}
.create-btn i {
  position: absolute;
  left: 15px;
  font-size: 25px !important;
  bottom: 10px;
  color: gray;
}
.create-btn img{
  width: 22px;
  position: absolute;
  left: 15px;
}

.invoice-item {
  color: white;
  background-color: #092558;
}

.articleNumb{
  width: 230px !important;
  cursor: pointer;
}
.articleNumb .form-select::placeholder{
  color: #000;
}
.articleNumb .form-select:focus{
  box-shadow: none;
  border-color: #a3a3ab;
}
.activeMenu .articleMegaMenu{
  opacity: 1;
    visibility: visible;
}
.articleMegaMenu {
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out;
  position: absolute;
  left: 0;
  z-index: 9;
  background: #fff;
  border: 3px solid lightgray;
  box-shadow: 0 11px 42px hsla(0,0%,100%,.25);
  width: 80%;
  height: 340px;
  overflow: scroll;
  overflow: auto;
  .table-responsive{
    border-radius: 0;
    overflow: auto;
    box-shadow: none;
    width: 80%;
    min-width: 80% !important;
    table thead th{
      border-top: none !important;
      border-bottom: 1px solid #dee2e6 !important;
      text-align: left;
    }
    table tbody tr td{
      text-align: left;
      cursor: pointer;
    }
    table tbody tr td .fa-star{
      color: orange;
    }
  }
  .favorite{
    width: 20%;
    min-width: 20%;
    border-left: 1px solid #dee2e6;
    p{
      background: white;
    font-weight: bold;
    padding: 11px 10px;
    line-height: 22px;
    text-align: center;
    margin: 0;
    }
    .list{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 0px);
  
      ul{
        margin-bottom: 0;
        li{
          list-style: none;
          padding: 9px 10px;
          &:last-child{
            margin-bottom: 0;
          }
          .form-check-input {
            width: 15px !important;
            height: 15px;
            background-color: none;
            border: 1px solid #0a2558;
            background-image: var(--bs-form-check-bg-image) !important;
            margin-right: 10px;
            &:checked {
              background-color: #0a2558;
            }
            &:focus {
              outline: none;
              box-shadow: none;
            }
        }
        }
      }
      button{
        width: 100%;
        padding: 10px;
        background-color: #0a2558;
        color: white;
        border: none;
        &:hover{
          background-color: #0a2558;
        }
      
      }
    }
  }
}
.favoriteMegaMenu{
  .articleMegaMenu{
    bottom: 113px;
    left: 54px;
  }
}
.fieldContent {
  overflow: hidden;
  /* text-overflow: ellipsis; */
  white-space: nowrap;
}

.order-table{
    .accordion{
      ul{
        padding: 0;
        margin: 0;
        list-style: none;
        li{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 0;
          border-bottom: 1px solid rgb(189, 189, 189);
          &:last-child{
            border-bottom: none;
          }
          i{
            cursor: pointer;
          }
        }
      }
    }
}

.fa-star {
  color: orange;
}
.selectedFavoriteTable {
  background-color: #cfe2ff;
  padding: 15px;
  font-weight: bold
}

.favListTable {
  .table-responsive {
   height: 300px;
   overflow: scroll;
  }
}

.edited-row {
  background-color:#ededc7;
}
#fa-star{
  color: blue;
}
.nav-link.disabled {
  opacity: 0.5 !important;
}

.work-order-bottom-btns{
  display: flex;
  gap: 10px;
}
.white-space-nowrap{
  white-space: nowrap;
}
@media only screen and (max-width: 992px){
  .work-order-bottom-btns{
    display: inline-block;
    gap: 10px;
    width: 100%;
  }
  .work-order-bottom-btns button{
    display: inline-block;
    white-space: nowrap;
    height: unset;
    padding: 10px 10px!important;
    margin-right: 10px!important;
    margin-bottom: 5px;
  }
}