@import "../../assets/scss/mixin";

.panel-sidebar {
  z-index: 9;
  height: 100vh;
  background: white;
  padding: 20px 0 20px 0;
  position: fixed;
  left: 0;
  top: 0;
  @include transition;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: scroll;

  &.show {
    width: 300px;
    .ps-sidebar-root {
      .ps-submenu-expand-icon {
        display: block !important;
      }
    }
  }

  &.hide {
    width: 60px;
    .ps-sidebar-root {
      .ps-submenu-expand-icon {
        display: none !important;
      }
    }
    figure {
      a {
        img {
          width: 35px;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint_tablet) {
    height: calc(100vh - 76px);
    background: #fff;
    top: auto;
    bottom: 0;

    &.hide {
      width: 300px;
      padding: 20px 10px 20px 10px;
      text-align: left;
    }

    &.show {
      width: 0;
      padding: 0;
      overflow: hidden;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  // &::-webkit-scrollbar-thumb {
  //   background-color: darkgrey;
  //   border-radius: 0.3rem;
  // }

  figure {
    margin: 0 10px 33px;

    a {
      display: inline-block;

      img {
        width: 50px;
      }
    }
  }

  .ps-sidebar-root {
    width: 100%;
    min-width: 100%;
    padding-bottom: 30px;

    .ps-sidebar-container {
      background-color: transparent;
      overflow: visible;

      // & :first-child {
      //   li:first-child {
      //     &:first-child {
      //       border: 1px solid #0a2558 !important;
      //       & + .css-12w9als {
      //         width: fit-content !important;
      //         flex-grow: 0 !important;
      //         -webkit-flex-grow: 0 !important;
      //       }
      //     }
      //   }
      // }

      ul {
        transition: height 0.5s linear;
        .ps-menuitem-root {
          &.active {
            background-color: transparent;
          }
        }

        .ps-submenu-root {
          margin-bottom: 0;
          background-color: transparent !important;
        }

        &.bottom-area {
          position: absolute;
          bottom: 32px;
        }

        .toggle-icon {
          > a:first-child {
            margin-bottom: 8px;
            & .css-12w9als {
              margin-right: 20px;
              width: fit-content !important;
              flex-grow: 0 !important;
              -webkit-flex-grow: 0 !important;
            }
            .ps-menu-suffix {
              width: 20px !important;
              height: 20px !important;
              margin-top: 5px;
            }
          }
        }

        li {
          margin-bottom: 14px;

          a {
            @include font(
              $family: (
                "Poppins",
                sans-serif,
              ),
              $size: 15px,
              $fWeight: 500
            );
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            color: #0a2558;
            padding: 0;
            white-space: nowrap;
            padding-left: 17px;
            padding-right: 18px;
            height: auto;
            @include transition;

            &.active-link {
              background-color: transparent !important;
              border-radius: 10px;
            }

            &:hover {
              background-color: transparent !important;
              border-radius: 10px;
            }

            .ps-menu-icon {
              justify-content: flex-start;
            }

            // i {
            //   width: 24px !important;
            //   height: 24px !important;
            //   margin: 0 8px 0 0;
            // }
            img {
              width: 24px !important;
              height: 24px !important;
              margin: 0;
              padding: 0;
              filter: brightness(0);
            }
            svg {
              width: 24px !important;
              height: 24px !important;
              margin: 0;
              padding: 0;
            }

            .ps-submenu-expand-icon {
              margin-left: auto;
              margin-right: 5px;
            }
          }

          .ps-submenu-content {
            background-color: #eceef2 !important;
            border-right: 3px solid #0a2558;
            transition: all 0.7s ease-in-out;
            // i {
            //   width: 24px !important;
            //   height: 24px !important;
            //   margin: 0 8px 0 0;
            // }
            img {
              width: 22px !important;
              height: 22px !important;
              margin: 0;
              padding: 0;
              filter: brightness(0);
            }
            svg {
              width: 24px !important;
              height: 24px !important;
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.panel-sidebar {
  .ps-sidebar-root {
    .ps-menu-button {
      .ps-menu-suffix {
        transition: all 0.3s linear;
      }
      & > span {
        &.ps-submenu-expand-icon {
          display: none !important;
        }
      }
      &.ps-open {
        .ps-menu-suffix {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.right-side{
  .select-wrapper {
    .lang{
      background-color: #fff !important;
      border: 2px solid #efeef1;
      height: 40px;
      margin: 0;
      width: 50%;
      margin-left: 17px;
    }
  }
}