.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.tabs {
  display: flex;
  cursor: pointer;
}

.tab {
  padding: 10px;
  border: 1px solid #ccc;
  border-bottom: none;
  margin-right: 5px;
  background: #f0f0f0;
}

.tab.active {
  background: #ffffff;
  border-bottom: 1px solid #ffffff;
}

/* .tab-content {
  display: none;
  border: 1px solid #ccc;
  padding: 10px;
} */

.tab-content.active {
  display: block;
}

.options {
  margin-top: 10px;
}

.option {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  background: #f9f9f9;
  cursor: pointer;
}

.option:hover {
  background: #e9e9e9;
}

.selected-option {
  margin-top: 20px;
}

