@import "../../assets/scss/mixin.scss";

.customListing {
  .simple-btn {
    min-width: fit-content;
  }

  .heading {
    h3 {
      @include font(
        $family: (
          "Poppins",
          sans-serif,
        ),
        $size: 24px,
        $fWeight: 500
      );
      margin-bottom: 0;

      @media screen and (max-width: $breakpoint_mobile_portrait) {
        margin-bottom: 10px;
      }
    }
  }

  .input_button {
    @media screen and (max-width: $breakpoint_mobile_portrait) {
      width: 100%;
    }
  }

  .alternative-colors {
    margin-top: 30px;
  }
  .select-wrapper {
    gap: 10px;
    margin-right: 10px;
    select {
      background-color: #fff !important;
      border: 2px solid #efeef1;
      height: 40px;
      margin: 0;
    }
  }
}
