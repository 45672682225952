@import "./mixin";
// @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: ("Poppins", sans-serif);
}

:root {
  --color-page-background: #{$dark_color};
  --color-text: #{$white_color};
  --theme-color: #{$theme_color};
  --white-color: #{$white_color};
  --black-color: #{$dark_color};
  --border: #{$dark_color};
  --font-family: ("Poppins", sans-serif);
}

body {
  margin: 0;
  background: #f5f5f5;
  color: var(--theme-color);
  -webkit-text-size-adjust: $w_100;
  -ms-text-size-adjust: none;
  font: 16px/25px var(--font-family);
  overflow-x: $hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
  font-family: ("Poppins", sans-serif);

  @media (max-width: $breakpoint_laptop) {
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
  font-family: var(--font-family);
  color: var(--theme-color);
}

.w-100 {
  width: $w_100;
}

.max-width {
  @include max_width;
}

.list-style-none {
  @include list_style_none;
}

.text-break {
  overflow-wrap: break-word;
}

.d-block {
  display: $d_block;
}

.d-none {
  display: $d_none;
}

.flex {
  display: $flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.content-justify-center {
  justify-content: center;
}

.content-justify-between {
  justify-content: space-between;
}

.content-justify-start {
  justify-content: flex-start;
}

.content-justify-end {
  justify-content: flex-end;
}

.column-direction {
  flex-direction: column;
}

.row-direction {
  flex-direction: row;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.m-0 {
  margin: 0;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

input[type="submit"],
button {
  cursor: pointer;
}

img {
  vertical-align: top;
  border-style: $d_none;
}

button:focus,
textarea:focus,
input:focus,
select {
  outline: $d_none;
}

textarea,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="date"],
select {
  color: #07070c;
  font-family: var(--font-family);
  @include font(
    $family: (
      "Poppins",
      sans-serif,
    ),
    $size: 14px,
    $fWeight: 400
  );
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: $transparent;
  color: $transparent;
  cursor: pointer;
  height: auto;
  @include position_abs_left;
  right: 0;
  bottom: 0;
  width: auto;
}

*:focus {
  outline: $d_none;
}

.transition {
  @include transition;
}

#wrapper {
  width: $w_100;
  position: $position_rel;
  overflow: $hidden;
}

.container {
  width: $w_100;
  max-width: 1140px;
  padding: 0 15px;
  margin: $margin_auto;
}

.container.fluid {
  max-width: $d_none;
  padding: 0 40px;

  @media (max-width: $breakpoint_tablet) {
    padding: 0 15px;
  }
}

.container:after {
  display: block;
  clear: both;
  content: "";
}

.position_abs_right {
  @include position_abs_right;
}

.position_abs_left {
  @include position_abs_left;
}

.relative {
  position: relative;
}

.row {
  margin: 0 -15px;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;

  @media (max-width: 800px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 15px;
}

.col-2 {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 15px;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.form-group {
  &.filed-title {
    label {
      &:last-child {
        width: 133px;
      }
    }
  }

  label {
    line-height: 19px;
    margin: 0 0 8px;
    color: #07070c;
    display: flex;
    gap: 8px;
    @include font(
      $family: (
        "Poppins",
        sans-serif,
      ),
      $size: 14px,
      $fWeight: 500
    );

    small {
      font-size: 14px;
      color: #b83c3c;
    }
  }
}

.form-check-input {
  width: 60px !important;
  height: 30px;
  background-color: #d4d4d4;
  border: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

.form-control,
.form-select {
  @include flex;
  height: 40px;
  background-color: #f1f1f1 !important;
  border: 1px solid #a3a3ab;
  border-radius: 5px;
  color: #07070c;
  font-size: 14px;
  padding-left: 12px;
  position: relative;

  &::placeholder {
    color: rgba(7 7 12 / 100%);
    @include font(
      $family: (
        "Poppins",
        sans-serif,
      ),
      $size: 14px,
      $fWeight: 400
    );
  }

  margin: 0 0 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../assets/images/select-down-angle.svg") no-repeat 96%
    center;
}

.width-50 {
  width: 50% !important;

  @media (max-width: $breakpoint_mobile_portrait) {
    width: 100% !important;
  }
}

table {
  background-color: transparent;
  max-width: 100%;
  border-collapse: collapse;
}

div[role="table"] [role="rowgroup"]:first-child [role="row"] .table-content {
  text-align: left;
  @include font(
    $family: (
      "Poppins",
      sans-serif,
    ),
    $size: 1.2em,
    $fWeight: 700
  );
}

div[role="table"] [role="rowgroup"] [role="row"] .jobPosition {
  @include font(
    $family: (
      "Poppins",
      sans-serif,
    ),
    $size: 1.05em,
    $fWeight: 400
  );
}

label {
  @include font(
    $family: (
      "Poppins",
      sans-serif,
    ),
    $size: 14px,
    $fWeight: 400
  );
}

table {
  width: 100%;
}

table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  //border: 1px solid #666666;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
}

table thead tr th {
  border-top: 1px solid #666666;
  text-align: center;
  text-transform: capitalize;
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 1px solid #666666;
}

table > thead > tr > th {
  border-bottom: 2px solid #666666;
  vertical-align: middle;
}

table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td {
  border-bottom: 0 none;
}

table > tbody + tbody {
  border-top: 2px solid #666666;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 19px 10px;
  @media (max-width: $breakpoint_laptop) {
    padding: 11px 10px;
  }
}

#root {
  overflow: hidden;
}

.min-width-auto {
  min-width: auto !important;
}

.timereport-textarea {
  background-color: #ffffff !important;
  border-radius: 3px;
  border: 1px solid #93b79a;
  min-height: 200px !important;
}

.simple-btn {
  display: flex;
  background-color: #0a2558;
  border-radius: 10px;
  min-width: 212px;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  color: #f8f8f8;
  font-size: 16px;
  border: 1px solid #0a2558;
  text-transform: capitalize;
  @include font(
    $family: (
      "Poppins",
      sans-serif,
    ),
    $size: 14px,
    $fWeight: 500
  );
  @include transition;

  @media screen and (max-width: $breakpoint_mobile_portrait) {
    font-size: 14px;
  }

  &:hover {
    background-color: transparent;
    color: #0a2558;
  }
}

.simple-btn-success {
  background-color: #93b79a;
  color: black;
  border: 1px solid #93b79a !important;
  border-radius: 3px;
  box-shadow: 2px 2px 3px #666666;
  &:hover {
    background-color: transparent;
    color: #93b79a;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.panel-main-wrapper {
  ::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 40px;
  border: 1px solid #a3a3ab;
}

.react-tel-input .flag-dropdown {
  z-index: 0;
  border: 1px solid #a3a3ab;
  background-color: #f1f1f1;
}

.cursor-pointer {
  cursor: pointer;
}

.card_layout {
  padding: 40px;
  background: white;
  // box-shadow: 1px 1px 11px 5px #80808030;
  // border-radius: 10px;

  input::placeholder {
    color: rgb(147, 147, 147);
    @include font(
      $family: (
        "Poppins",
        sans-serif,
      ),
      $size: 14px,
      $fWeight: 400
    );
  }
}

.heading {
  h3 {
    @include font(
      $family: (
        "Poppins",
        sans-serif,
      ),
      $size: 24px,
      $fWeight: 500
    );
    color: #0a2558;
    margin-bottom: 15px;
    text-transform: capitalize;
  }
}

.table-responsive {
  min-width: 97%;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  &.alternative-colors {
    table {
      tr {
        th {
          background: #0a2558;
          color: #f8f8f8;
          font-weight: 400;
        }
      }

      tbody {
        tr {
          &:nth-of-type(even) {
            background-color: #ececec;
          }

          &:nth-of-type(odd) {
            background-color: #f5f5f5;
          }

          &:hover {
            background-color: #ffff;
          }
        }
      }
    }

    .rdt_TableRow {
      &:nth-of-type(even) {
        background-color: #ececec;
      }

      &:nth-of-type(odd) {
        background-color: #f5f5f5;
      }

      &:hover {
        background-color: #ffff;
      }
    }

    .rdt_TableHeadRow {
      .rdt_TableCol {
        &:first-child {
          padding-left: 16px;
        }
      }
    }

    .rdt_TableRow {
      padding-left: 16px;
    }

    div {
      &[role="table"] {
        [role="rowgroup"] {
          &:first-child {
            [role="row"] {
              & > div {
                background: #0a2558;
              }

              .table-content {
                color: #f8f8f8;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  & > div {
    &:last-child {
      nav {
        background-color: transparent;
        justify-content: flex-start;

        & > div {
          &:last-child {
            margin-left: auto;
          }
        }
      }
    }
  }

  div {
    &[role="table"] {
      background-color: transparent;

      [role="rowgroup"] {
        &:first-child {
          [role="row"] {
            & > div {
              &:first-child {
                border-radius: 10px 0 0 0;

                .rdt_TableCol_Sortable {
                  justify-content: start;
                }
              }

              &:last-child {
                border-radius: 0 10px 0 0;
              }
            }
          }

          .table-content {
            font-weight: 400;
          }
        }

        [role="row"] {
          // background-color: transparent;
          // border: none;
          height: auto;

          &:last-child {
            border-bottom: 1px solid #e3e3e3;
          }

          figure {
            margin: 0;
            display: flex;
            align-items: center;

            img {
              display: flex;
              width: 35px;
              flex: 0 0 35px;
              margin-right: 10px;
              height: 35px;
              border-radius: 100%;
              object-fit: cover;
            }
          }

          & > div {
            text-align: left;
            width: 235px;
            @media screen and (max-width: 600px) {
              width: auto;
            }
            padding: 0;

            & > div {
              // padding: 0 16px;
            }

            div {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
            }
          }

          .rdt_TableCol_Sortable {
            justify-content: flex-start;
          }

          .table-content {
            color: #07070c;
            width: auto;

            span {
              background: #0176d3;
              border-radius: 10px;
              color: white;
              padding: 8px 15px;
            }
          }

          .jobPosition {
            padding-left: 16px;

            h3 {
              margin: 0;
              font-size: 14px;
            }

            p {
              font-size: 14px;
              margin: 0;
              display: flex;
              color: #a3a3ab;

              img {
                margin-right: 5px;
                width: 17px;
              }
            }
          }
          .editedRow {
            background-color: #93b79a;
          }

          .vacant_shift {
            border-radius: 10px;
            margin: 10px 0;
            width: 100%;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              margin: 0;
              font-size: 18px;
            }
          }
        }
      }
    }

    .search-filter {
      select {
        width: 200px;
        border-left: 1px solid #e3e3e3;
        border-radius: 5px;
        background-position: 185px center;
      }
    }
  }
}

.loginMain {
  min-height: 100vh;
  @include font(
    $family: (
      "Poppins",
      sans-serif,
    ),
    $size: 16px,
    $fWeight: 500
  );

  .loginBox h3 {
    margin-bottom: 0;
    font-weight: 800;
    color: #0a2558;
  }
}

.loginImage {
  background-image: url(https://e0.pxfuel.com/wallpapers/386/919/desktop-wallpaper-website-background-website-login-page-background.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;
  color: white;
  min-height: 100vh;

  @media screen and (max-width: $breakpoint_tablet) {
    min-height: auto;
    padding: 25px 0;
  }

  .logo {
    padding: 0 50px;

    @media screen and (max-width: $breakpoint_tablet) {
      padding: 0 25px;
    }

    h4 {
      font-weight: 800;
      font-size: 30px;
      line-height: 48px;
      color: white;
      margin-top: 30px;

      @media screen and (max-width: $breakpoint_tablet) {
        font-size: 22px;
        margin-top: 15px;
        margin-bottom: 0;
      }
    }

    img {
      width: 130px;

      @media screen and (max-width: $breakpoint_tablet) {
        width: 80px;
      }
    }
  }
}

.loginBox input::placeholder {
  color: rgb(147, 147, 147);
  @include font(
    $family: (
      "Poppins",
      sans-serif,
    ),
    $size: 14px,
    $fWeight: 400
  );
}

.loginBox {
  background: white;
  padding: 20px 30px;
  box-shadow: 1px 1px 11px 5px #80808030;
  border-radius: 10px;

  @media screen and (max-width: $breakpoint_tablet) {
    margin: 50px 0;
  }
}
.nav-tabs {
  border: 0;
}
.nav-item {
  margin: 2px;
}
.nav-pills {
  border: 1px solid #c93806;
  display: inline-flex !important;
  border-radius: 8px;
  width: 100%;
}
.nav-link.active {
  background-color: #0a2558 !important;
  @include font(
    $family: (
      "Poppins",
      sans-serif,
    ),
    $size: 14px,
    $fWeight: bold
  );
  border-radius: 0 !important;
  line-height: 20px;
  color: #ffffff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: underline;
}
.nav-link {
  background: #e2e2e2 !important;
  @include font(
    $family: (
      "Poppins",
      sans-serif,
    ),
    $size: 14px,
    $fWeight: bold
  );
  border-radius: 0 !important;
  line-height: 20px;
  color: black !important;
  text-decoration: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .nav-item {
    width: 48%;
    margin: 2px;
  }
}
// .table-responsive div[role=table] [role=row] {
//   padding: 0 10px;
//   background: #0a2558;
// }

@media screen and (max-width: 600px) {
  .select-wrapper {
    width: inherit;
  }
  .input_button {
    flex-wrap: wrap;
    .simple-btn {
      margin-top: 3px;
      @include font(
        $family: (
          "Poppins",
          sans-serif,
        ),
        $size: 14px,
        $fWeight: 500
      );
    }
  }
}
textarea {
  height: auto !important;
}
