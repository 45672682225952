@import "../assets/scss/mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.panel-wrapper {
  .panel-main-content {
    margin-left: 300px;
    width: calc(100% - 300px);
    padding: 0;
    position: relative;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;

    &.hide {
      margin-left: 60px;
      width: calc(100% - 60px);
      .panel-header {
        margin-left: 60px;
        width: calc(100% - 60px);
      }
    }

    @media screen and (max-width: $breakpoint_tablet) {
      &.hide {
        margin-left: 0;
        width: 100%;
        .panel-header {
          margin-left: 0;
          width: 100%;
        }
      }

      &.show {
        margin-left: 0;
        width: 100%;
        .panel-header {
          margin-left: 0;
          width: 100%;
        }
      }
    }

    .panel-header {
      width: calc(100% - 300px);
      height: 90px;
      background: white;
      padding: 0 25px;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 999;
      // background-color: #0a2558 !important;
      transition: all 0.2s ease-in-out;

      @media screen and (max-width: $breakpoint_tablet) {
        padding: 0 15px;
      }

      .select-wrapper {
        gap: 10px;
        margin-right: 10px;
        select {
          background-color: #fff !important;
          border: 2px solid #efeef1;
          height: 40px;
          margin: 0;
        }
      }

      .left-side {
        h1 {
          margin: 0;
          font-weight: 700;
          font-size: 24px;
          line-height: 38px;
          margin-right: 30px;
        }
        .side-menu-btn {
          background: transparent;
          padding: 5px 10px;
          font-size: 20px;
          border: 2px solid #e9e9e9;
          border-radius: 5px;
          @include transition;
          // position: fixed;
          // top: 22px;
          // z-index: 9999;

          &.show {
            left: 315px;

            @media (max-width: $breakpoint_tablet) {
              left: 15px;
            }
          }

          &.hide {
            left: 85px;

            @media (max-width: $breakpoint_tablet) {
              left: 15px;
            }
          }

          @media (max-width: $breakpoint_tablet) {
            display: block;
          }

          img {
            width: 17px;
            height: 23px;
            filter: opacity(0.5);
          }
        }
      }

      .right-side {
        display: flex;
        align-items: center;
        white-space: nowrap;

        figure {
          margin: 0;

          img {
            flex: 0 0 36px;
            height: 36px;
            border-radius: 100%;
            margin-right: 8px;
            display: flex;
            object-fit: cover;
          }

          figcaption {
            line-height: 1;
          }

          strong {
            @include font(
              $family: (
                "Poppins",
                sans-serif,
              ),
              $size: 14px,
              $fWeight: normal
            );
            position: relative;
            // padding-right: 19px;
            display: block;
            line-height: 19px;

            &:before {
              // content: '';
              position: absolute;
              right: 0;
              top: 8px;
              width: 8px;
              height: 5px;
              background: url("../assets/images/down-angle-arrow.svg") no-repeat
                center;
              background-size: cover;
            }
          }

          small {
            line-height: 16px;
            color: #767682;
            @include font(
              $family: (
                "Poppins",
                sans-serif,
              ),
              $size: 12px,
              $fWeight: normal
            );
          }
        }
      }
    }

    .panel-main-wrapper {
      min-height: calc(100vh - 90px);
      padding: 35px 20px 0;
      margin-top: 90px;

      @media screen and (max-width: $breakpoint_tablet) {
        padding: 25px 10px 0;
      }

      .panel-card {
        margin: 0 auto 70px;
        width: 100%;
        border: 3px solid #0a2558;
        .panel-body {
          background: #f8f8f8;
          box-shadow: 20px 20px 11px rgba(0, 0, 0, 0.01),
            11px 11px 9px rgba(0, 0, 0, 0.05), 5px 5px 7px rgba(0, 0, 0, 0.09),
            1px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          padding: 24px 16px;
        }
      }
    }
  }
}

.table-btn {
  display: inline-block;
  margin: 2px 0 2px 5px;
  background: var(--theme-color);
  font-size: 12px;
  border-radius: 0.3rem;
  border: none;
  letter-spacing: 1px;
  white-space: nowrap;
  color: var(--white-color);
  line-height: 30px;
  padding: 0 15px;
  @include transition;

  i {
    margin-right: 5px;
  }

  &:hover {
    color: #ffffff !important;
    background: var(--theme-color);
  }
}

.header-search {
  position: relative;
  margin-right: 10px;
  width: 275px;

  @media screen and (max-width: $breakpoint_mobile_portrait) {
    width: 100%;
  }

  input {
    width: 100%;
    height: 40px;
    font-weight: 500;
    padding-left: 12px;
    padding-right: 38px;
    outline: none;
    background: #ffff;
    border: 2px solid #efeef1;
    border-radius: 6px;
    font-size: 14px;
    color: #8a8a8a;

    &::placeholder {
      color: #8a8a8a;
    }
  }

  button {
    background: #0a2558;
    border: none;
    padding: 0;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 4px;
    top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    svg {
      color: white;
    }
  }
}
.lang {
  border: 1px solid #ddd;
  width: 150px;
  height: 45px;
  padding-left: 15px;
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.755);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
